.image-btn {
    padding: 0 !important;
    border: 2px solid #fff;
}
.image-btn:hover {
    border: 2px solid #fff;
}
.image-btn:focus {
    border: 2px solid #fff;
}
.image-btn:active {
    border: 2px solid #fff !important;
}
.project-selected {
    border: 2px solid #00b5ad;
    padding: 5px;
}

.project-list-container {
    overflow-x: auto;
    white-space: nowrap;
    padding: 20px;

    .project-list-row {
        display: flex;
        flex-wrap: nowrap;
        margin: 0;
    }
    
    .project-list-col {
        
    }
    
    .project-list-card {
        max-width: 300px;
        word-wrap: break-word;
    }
    
    @media (max-width: 576px) {
        .project-list-col {
            margin-right: 0;
        }
    }
}

